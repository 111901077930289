.react-toggle {

    padding-bottom: 2px !important;

    touch-action: pan-x;
  
    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
  
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
  }
  
  .react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  .react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
  }
  
  .react-toggle-track {
    box-shadow: inset 0px -1px 4px rgba(0, 0, 0, 0.11);
    width: 72px;
    height: 40px;
    padding: 0;
    border-radius: 8px;
    background-color: var(--light-gray);
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  
  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: var(--light-gray);
  }
  
  .react-toggle--checked .react-toggle-track {
    background-color: var(--light-gray);
  }
  
  .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: var(--light-gray);
  }
  
  .react-toggle-track-check {
    position: relative;
    width: 20px;
    height: 20px;
    top: 10px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 10px;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;

    display: flex;
    align-items: center;
  }
  
  .react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }
  
  .react-toggle-track-x {
    position: relative;
    width: 20px;
    height: 20px;
    right: -41px !important;
    top: -9px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;

    display: flex;
    align-items: center;
  }
  
  .react-toggle--checked .react-toggle-track-x {
    opacity: 0;
  }
  
  .react-toggle-thumb {
    margin-top: 1px;
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 3px;
    left: 3px;
    width: 32px;
    height: 32px;
    border: 1px solid #F0F0F0;
    border-radius: 7px;
    background-color: var(--blue);
    margin-left: 2px;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;

    /*Center icon (svg) inside */
    display: flex;
    align-items: center;
  }

  .react-toggle-thumb:hover {
    background-color: var(--blue-hover);
  }
  
  .react-toggle--checked .react-toggle-thumb {
    left: 34px;
   /* border-color: #19AB27;*/
  }
  
  .react-toggle--focus .react-toggle-thumb {
   /* -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
    -moz-box-shadow: 0px 0px 3px 2px #0099E0;
    box-shadow: 0px 0px 2px 3px #0099E0;*/
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  
  .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    /*-webkit-box-shadow: 0px 0px 5px 5px #0099E0;
    -moz-box-shadow: 0px 0px 5px 5px #0099E0;
    box-shadow: 0px 0px 5px 5px #0099E0;*/
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .toggle-icon{
    margin: 0 auto;
    display: block;
    width: 20px;
    height: 20px;
  }