input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus,
input:-webkit-autofill::first-line,
input:-webkit-autofill::after {
    font-family: var(--font-main);
}

input:-internal-autofill-selected {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.form-control::placeholder {
    color: var(--font-gray);
}

.form-control::-ms-input-placeholder {
    color: var(--font-gray);
}

.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: var(--main) !important;
    outline: 0;
    box-shadow: none !important
}

.form-control:disabled {
    background-color: var(--input-gray-disabled) !important;
}

.modal-form-group {
    margin-bottom: 18px !important;
}

h2 {
    font-weight: 800;
    font-size: 1rem;
    color: #373737;
}

hr {
    height: 2px !important;
    color: #F1F4F7;
    opacity: 1;
}

.table {
    color: var(--font-black);
}

table th:first-child {
    border-radius: 10px 0 0 10px;
}

table th:last-child {
    border-radius: 0 10px 10px 0;
}

td {
    padding: 5px;
    text-align: left;

}

.small {
    font-size: 0.75rem;
}

.date-range {
    border-radius: 5px;
    border: 2px solid var(--light-gray);
    height: 36px;
}

.gauge-selected-outline {
    border-radius: 16px !important;
    border: 1.5px solid var(--main) !important;
}

.gauge-selected-outline-tabbed {
    border-radius: 16px 16px 0px 0px !important;
    border-top: 1.5px solid var(--main) !important;
    border-left: 1.5px solid var(--main) !important;
    border-right: 1.5px solid var(--main) !important;
    background-color: white;
    padding-bottom: 5px;
    z-index: 1;
    box-shadow: 0px 3px 0px -1.5px #ffffff;
}

.tabbed-content-selected-start {
    border-radius: 0px 16px 16px 16px !important;
    border: 1.5px solid var(--main) !important;
    background-color: white;
    z-index: 0;
    margin-top: -16px;
    margin-left: 8px;
    margin-right: 4px;
    margin-bottom: 15px;
}


.tabbed-content-selected {
    border-radius: 16px 16px 16px 16px !important;
    border: 1.5px solid var(--main) !important;
    background-color: white;
    z-index: 0;
    margin-top: -16px;
    margin-left: 4px;
    margin-right: 4px;
    margin-bottom: 15px;
}

.tabbed-content-selected-end {
    border-radius: 16px 0px 16px 16px !important;
    border: 1.5px solid var(--main) !important;
    background-color: white;
    z-index: 0;
    margin-top: -16px;
    margin-left: 4px;
    margin-right: 8px;
    margin-bottom: 15px;
}

.section-outline {
    border-radius: 16px !important;
    border: 1.5px solid var(--light-gray);
}

.right-container {
    margin-top: 88px;
    /*10 top + 46 height + 32 required*/
}


.container {
    max-width: 100% !important;
    margin-bottom: 162px;
}


.background-blue {
    background-color: var(--main);
}

.background-light-blue {
    background-color: var(--light-blue);
}

.background-gray {
    background-color: var(--light-gray-2);
}

.background-default {
    background-color: var(--backgrounds);
}


.text-main-blue {
    color: var(--main) !important;
}

.fill-blue {
    fill: var(--main)
}

.fill-blue:hover {
    fill: var(--main-hover)
}

.side-navigation-item {
    font-weight: 500;
    color: var(--title-font-color);
}

.side-navigation-item:hover {
    color: var(--main) !important;

}


.side-navigation-item span {
    padding-top: 5px;
    padding-right: 3px;
    padding-bottom: 4px;
    padding-left: 9px;
    border-radius: 8px;
    border: solid transparent 2px;
    margin-right: 10px;
    margin-left: -6px;
}


.navigation-selection {
    border-radius: 8px;
    border: solid !important;
    padding-top: 5px;
    padding-right: 3px;
    padding-bottom: 4px;
    padding-left: 9px;
    width: 100%;
    margin-right: 10px;
    margin-left: -6px;
    box-shadow: inset 0px -1px 1px rgb(0 0 0 / 11%);
    font-weight: 600;
}

.section-tab-selector {
    box-shadow: inset 0px -1px 4px rgba(0, 0, 0, 0.11);
    margin-left: 20px;
    margin-right: 10px;
    background-color: var(--input-gray);
    border-radius: 8px;
    /*border: 2px solid*/
}

.section-tab-selector .nav-tabs .nav-item {
    flex: 0 0 auto;
    width: 50%;
}

.section-tab-selector .nav-tabs .nav-link {
    border: none;
    cursor: pointer;
    color: var(--main);
    font-weight: 500;
    padding: 8px;
    width: 100%;
    font-size: 0.875rem;
    min-width: 68px;
}

.nav-tabs .nav-link.active {
    background-color: white;
    border-radius: 8px 8px 0px 0px;
    border: 2px solid var(--main);
    border-bottom: 0px;
    margin-bottom: -2px;
}

.nav-tabs .nav-link {
    border: none;
    cursor: pointer;
    color: black;
    font-weight: 500;
    padding: 8px;

    font-size: 0.75rem;
    min-width: 68px;
}

.navbar-item {
    display: inline-block;
    margin: 0px 15px;

}

.nav-tabs .navbar-item a {
    font-size: 1vw;
}

.sidebar-selected {
    background-color: var(--main);
}

.nav-tabs {
    border: none;
    margin-top: 20px
}

.tab-outline {
    border: 2px solid var(--main);

}

.form-check-input-grey {
    border-color: var(--input-gray) !important;
}

.form-check-input {
    cursor: pointer;
    border: 1.5px solid;
    background-color: transparent;
    border-color: var(--main);
}

.form-check-input:focus {
    box-shadow: none !important;
}

.form-check-input:checked {
    cursor: pointer;
    background-color: var(--main);
    border-color: var(--main);
}

.btn-primary {
    font-size: 0.875rem;
    color: #fff;
    background-color: var(--main);
    border-color: var(--main);
    box-shadow: inset 0px -1px 4px rgba(0, 0, 0, 0.11);
    border-radius: 8px;
}

.btn-primary:hover {
    color: #fff !important;
    background-color: var(--main-hover) !important;
    border-color: var(--main-hover) !important;
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: var(--main-disabled);
    border-color: var(--main-disabled);
}

.btn-check:focus+.btn-primary,
.btn-primary:focus {
    color: #fff;
    /*background-color: var(--main);
    border-color: var(--main);
    box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);*/
    background-color: var(--main);
    border-color: var(--main);
    box-shadow: none !important;
}

.btn-secondary {
    font-size: 0.875rem;
    color: #000;
    font-weight: 500;
    background-color: transparent;
    border: 2px solid;
    border-color: var(--main) !important;
    border-radius: 8px;
}

.btn-secondary:focus {
    color: var(--main);
    font-weight: 500;
    background-color: transparent !important;
    border: 2px solid;
    border-color: var(--main) !important;
    border-radius: 8px;
    box-shadow: none !important;
}

.btn-secondary:hover {
    color: var(--main);
    font-weight: 500;
    background-color: transparent !important;
    border: 2px solid;
    border-color: var(--main) !important;
    border-radius: 8px;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    color: var(--font-gray);
    background-color: #fff;
    border-color: var(--main-disabled);
}

.show>.btn-secondary.dropdown-toggle {
    color: #000;
    font-weight: 500;
    background-color: transparent;
    border: none;
}

.floating-header-brand {
    position: relative;
    left: 20px;
    top: 13px;
    height: 100px;
    width: 105px;
}

.floating-header-icon {
    position: absolute;
    top: 13px;
    right: 38px;
    width: 17px;
}

.floating-header-container {
    position: absolute;
    padding-right: 20px;
    top: 10px;
    width: 100%;
    height: 46px;
}

.floating-header {
    width: 100%;
    height: 100%;
    background-color: var(--main);
    border-radius: 0px 8px 8px 0px;
    box-shadow: 0px 2px 4px rgba(131, 134, 163, 0.16);
}


.logo {
    max-width: 164px;
    margin: 2vw auto 24px auto;
    width: 100%;
    min-height: 60px;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: contain;
}

.login-button-container {
    width: 100%;
}

.login-button {
    width: fit-content;
    /*box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);*/
    box-shadow: inset 0px -1px 4px rgba(0, 0, 0, 0.11);

    font-size: 0.875rem;
    box-sizing: border-box;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    cursor: pointer;
    outline: none;
    border: none;
    -webkit-tap-highlight-color: transparent;
    display: block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 0;
    min-width: 64px;
    line-height: 36px;
    padding: 0 16px;
    border-radius: 8px;
    overflow: visible;
    transform: translate3d(0, 0, 0);
    transition: background 400ms cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
    margin: auto;
}

.login-forgot-password {
    margin-top: -0.7rem;
}

.login-form-title {
    font: 800 normal normal 1.8vw "Montserrat", sans-serif;
    color: var(--login-welcome-color);
    letter-spacing: 0.75px;
    display: block;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.login-leftside {
    width: 100%;
    height: 100vh;
}

.login-leftside-image {
    width: 44vw;
    height: 100vh;
    margin-top: 130px;
    margin-left: 3vw;
    /*background-image: url(/assets/img/loginBackground.svg);*/
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;
    filter: var(--login-svg-filter);
}

.login-rightside {
    padding-right: 5.5556vw;
    padding-top: 9.78vh;
    padding-bottom: 9.78vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 50vw;
    min-width: 45%;
    height: 100vh;
    border-radius: 30px;
}

.login-trademark {
    width: 25%;
    position: absolute;
    top: 48px;
    right: 48px;
    color: var(--main);
    display: block;
    /* font-size: 2em; */
    /* margin-block-start: 0.67em; */
    /* margin-block-end: 0.67em; */
    /* margin-inline-start: 0px; */
    /* margin-inline-end: 0px; */
    font-weight: bold;
    max-width: 140px;
}

.login-title {
    margin-top: 48px;
    margin-left: 48px;
    position: absolute;
    max-width: 424px;
    color: var(--login-welcome-color);
    font: 700 normal normal 32px "Montserrat", sans-serif;
    line-height: 36px !important;
    letter-spacing: 1.2px;
}

.login-container {
    overflow: hidden;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    background-color: var(--backgrounds);
}


.login-panel {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 30px;
    background-color: white;
    margin: 0 48px;
    height: 100%;
    width: 100%;
}

.text-block-overflow-ellipsed {
    clear: both;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.top-2 {
    top: 2px;
}

.top-4 {
    top: 4px;
}

.top-5 {
    top: 5px;
}

.pagination-wrapper {
    overflow-x: auto;
}

.pagination {
    justify-content: center;
    margin: 30px;
}

.gauge-value-label {
    position: relative;
    top: -75px;
    left: 0px;
    color: var(--font-black);
    text-align: center;
    align-items: end;
}

.gauge-subdata {
    margin-top: -75px;
}

.mood-icon-on {
    fill: white;
}

.mood-icon-on:hover {
    fill: white;
}

.mood-icon-off {
    fill: var(--main);
}

.mood-icon-off:hover {
    fill: var(--main-hover);
}

.items-start.selected div {
    color: var(--main);
    font-weight: 600;
}

.items-start div {
    font-weight: 500;
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: var(--main);
    border-color: var(--main);
}

.page-link {
    position: relative;
    display: block;
    color: var(--main);
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #dee2e6;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}


/***
El fill del svg debe ser negro
*/
.svg-icon-button {
    filter: var(--svg-color-filter);
}

.svg-icon-button:hover {
    filter: var(--svg-hover-color-filter);
}

.page-link:focus {
    box-shadow: none !important;
    color: var(--main);
}

.text-sm-vw {
    font-size: 1vw;
}

.text-sm-2-vw {
    font-size: 1.5vw;
}

.text-md-vw {
    font-size: 2vw;
}

.text-md-2-vw {
    font-size: 2.5vw;
}

.text-lg-vw {
    font-size: 3vw;
}

.text-lg-2-vw {
    font-size: 3.5vw;
}

.text-xl-vw {
    font-size: 4vw;
}

.risk-outline {
    border-radius: 10px !important;
    border: 1px solid var(--input-gray);
}

.user-settings {
    position: absolute;
    max-width: 260px;
    width: 260px;
    right: 2%;
    top: 57px;
    background: #FFFFFF;
    box-shadow: 1px 2px 20px rgb(81 108 150 / 14%);
    border-radius: 12px;
}

.rect-label {
    color: white;
    margin: 3px 15px;
    padding: 5px;
    border-radius: 6px;
    min-width: 80px;
    text-align: center;
    box-shadow: inset 0px 0px 4px rgba(0, 0, 0, 0.297432);
}


@media (min-width: 640px) {
    .modal-dialog {
        width: 100%;
        margin: 0px;
        height: 100%;
        padding-top: 40px;
        padding-bottom: 40px;
        padding-left: 40px;
        padding-right: 40px;
        min-width: 100%;
        min-height: 50vw;

        /*Limit size Y and apply scroll inside (modal-content)*/
        overflow: hidden;
    }
}

@media (min-width: 1080px) {
    .modal-dialog {
        width: 100%;
        margin: 0px;
        height: 100%;

        min-width: 100%;
        min-height: 50vw;

        /*Limit size Y and apply scroll inside (modal-content)*/
        overflow: hidden;
    }
}


.modal-content {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 15px;
    padding-left: 15px;
    box-shadow: 0px 0px 64px rgba(33, 41, 53, 0.235247), inset 0px -1px 10px rgba(230, 230, 230, 0.72);
    border-radius: 30px;
    border: none;
    /*Limit size Y and apply scroll*/
    max-height: 100%;
}

.modal-scrolled-content {
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: 70vh;
    padding-right: 15px;
}

.modal-body {
    text-align: center;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100vw;
    height: 100vh;
    /* background-color: rgba(255, 255, 255, 0.1);*/
    background-color: transparent;
}

.modal-backdrop.show {
    opacity: 1;
    filter: blur(1px);
    -webkit-filter: blur(1px);
    /* -webkit-filter: contrast(89%); */
    backdrop-filter: blur(3px) saturate(162%) opacity(90%);
}

.form-control {
    font-size: 0.875rem;
    height: 36px;
    border: 1px solid var(--main);
    border-radius: 8px;
}

.form-control.is-invalid {
    background-image: none;
    border: 1px solid var(--main-disabled);
}

.form-control:focus {
    border-color: var(--main);
}

/*
.modal.show .modal-dialog {
    transform: translateY(50%);
}*/

/**
Roundeds
*/
.rounded-lg {
    border-radius: 30px;
}

/**
Shadows
*/

.shadow-xl {
    box-shadow: inset 0px -1px 10px rgb(230 230 230 / 72%);
}

/**
Custom styles of react-select, prefix: react-select
**/

.react-select__control {
    background-color: #FFFFFF !important;
    border: 1px solid var(--input-gray) !important;
    border-radius: 8px !important;
    border-style: solid !important;
    border-width: 1px !important;
    min-height: 36px !important;
    /*min-height: 26px !important;
    max-height: 26px !important;*/
}

.react-select__control--is-focused {
    border: 1px solid var(--main) !important;
    box-shadow: none !important;
}


.react-select__indicator {
    color: var(--main) !important;
    padding: 5px !important;
}

.react-select__indicator-separator {
    visibility: hidden;
}



.react-select__multi-value {
    background-color: transparent !important;
}

.react-select__multi-value__label {
    font-size: 0.75rem !important;
    font-weight: 500 !important;
    color: var(--font-black);
}

.react-select__multi-value__remove {
    display: none !important;
}

.react-select__option--is-selected {
    background-color: white !important;
    color: var(--font-black) !important;
}

.react-select__option--is-selected .react-select-option-check {
    display: inline-block;
}

.react-select-option-check {
    display: none;
    color: var(--main);
}

.react-select__placeholder {
    text-align: left;
}

.react-select__single-value {
    text-align: left;
}

/*
DATEPICKER
*/

.date-range {
    border-radius: 5px;
    border: 1px solid var(--light-gray);
    height: 36px;
    border-radius: 8px;
}

.comments-container {
    overflow-y: scroll;
    overflow-x: hidden;
    /* Hide horizontal scrollbar */
    height: 386px;
}

.commment-item {
    border-left: 4px solid var(--main) !important;
}

.comment-item.folded .comment-text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.comment-scope {
    color: #C1C4CD;

}

.comment-sign {
    text-align: right;
    font-size: 0.875rem;
    display: inline-flex;
}

.comment-read-more {
    text-align: right;
    color: #8CB6F7;
    text-decoration: underline;
}

.sign-button {
    border: 1px solid #F1F4F7;
    border-radius: 100px;
    padding: 4px 8px;
}

.sign-button.positive.selected {
    background: rgba(106, 186, 33, 0.2);
}

.sign-button.neutral.selected {
    background: rgba(244, 202, 3, 0.2);
}

.sign-button.negative.selected {
    background: rgba(183, 16, 9, 0.2);
}

.sign-circle {
    height: 10px;
    width: 10px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.214719);
    border-radius: 100px;
}

.sign-circle.neutral.selected {
    background-color: var(--neutral);
}

.sign-circle.neutral {
    background-color: transparent;
    border: 2px solid var(--neutral);
}

.sign-circle.positive.selected {
    background-color: var(--positive);
}

.sign-circle.positive {
    background-color: transparent;
    border: 2px solid var(--positive);
}

.sign-circle.negative.selected {
    background-color: var(--negative);
}

.sign-circle.negative {
    background-color: transparent;
    border: 2px solid var(--negative);
}

.link {
    color: var(--main) !important;
}

.link:hover {
    color: var(--main-hover) !important;
}

.dpp-left-text {
    transform: rotate(270deg);
    text-align: center;
    width: 140px;
    height: 0px;
    position: relative;
    left: -65px;
    top: 95px;
    white-space: nowrap;
}

/**
Custom  styles of react-select for navigation section, prefix: react-select-nav
*/

.react-select-nav__indicator-separator {
    visibility: hidden;
}

.text-black {
    color: var(--font-black) !important;
}

.font-page-title {
    color: var(--title-font-color);
    font-weight: 800;
}

.text-gray,
.text-gray-300,
.text-gray-400 {
    color: var(--font-gray);
}

.text-gray-600 {
    color: var(--font-black);
}

.text-2xs {
    font-size: 0.5rem;
}

.text-3xs {
    font-size: 0.25rem;
}

.table>thead {
    vertical-align: middle;
}

/*Scrollbars*/
/* width */
::-webkit-scrollbar {
    width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: var(--light-gray);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--font-gray);
}

/**
TOOLTIPS
*/

.tooltip {
    font-family: var(--font-main);
}

.tooltip>div.tooltip-inner {
    box-shadow: 1px 2px 20px rgba(81, 108, 150, 0.24);
    background-color: white !important;
    color: black !important;
    font-size: 0.75rem;
}

.tooltip.show {
    opacity: 1 !important;
}

.tooltip>div.arrow::before {
    border-bottom-color: white !important;
    color: black !important;
}

.tooltip.bottom .tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -5px;
    border-bottom-color: white !important;
    border-width: 0 5px 5px;
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,
.bs-tooltip-bottom .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 0.4rem 0.4rem;
    border-bottom-color: white !important;
}

.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,
.bs-tooltip-bottom .tooltip-arrow::before {
    top: -1px;
    border-width: 0.4rem 0.4rem 0;
    border-top-color: white !important;
}

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,
.bs-tooltip-start .tooltip-arrow::before {
    left: -1px;
    border-width: 0.4rem 0 0.4rem 0.4rem;
    border-left-color: white !important;
}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,
.bs-tooltip-end .tooltip-arrow::before {
    right: -1px;
    border-width: 0.4rem 0.4rem 0.4rem 0;
    border-right-color: white !important;
}

.date-range {
    padding-left: 30px;
    padding-right: 30px;
}

.date-range-calendar-icon {
    position: relative;
    top: 10px;
    right: -25px;
}

.date-range-cross-icon {
    position: relative;
    top: 9px;
    color: var(--main);
    left: -55px;
}

.date-range-arrow-icon {
    position: relative;
    top: 11px;
    left: -25px;
    pointer-events: none;
}


/**
* VALIDATIONS
*/

.invalid-feedback {
    font-size: 0.75rem !important;
    text-align: left;
}


.h-44 {
    height: 11rem;
}

.input-checkbox-shadow {
    box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.214719);
}

/**
CALENDAR
*/
.calendar-container {
    right: 8.8rem;
    top: 8.5rem;
    z-index: 999;
    border: 1px solid var(--main);
    border-radius: 8px;
}

.rdrCalendarWrapper {
    border-radius: 8px;
}

.rdrInRange {
    background-color: var(--main) !important;
}

.rdrStartEdge {
    background-color: var(--main) !important;
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
    left: 8px !important;
}

.rdrEndEdge {
    background-color: var(--main) !important;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    right: 8px !important;
}

.rdrDayToday .rdrDayNumber span:after {
    content: '';
    position: absolute;
    bottom: 4px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 18px;
    height: 2px;
    border-radius: 2px;
    background: var(--main) !important;
}

.rdrStaticRange {
    z-index: 999;
    border-radius: 8px;
}

.rdrDateDisplayWrapper {
    border-radius: 8px;
}

.rdrDefinedRangesWrapper {
    border-radius: 8px;
}

.rdrStaticRangeSelected {
    color: var(--main) !important
}

.rdrDayStartPreview {
    color: var(--main) !important;
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
    left: 8px !important;
}

.rdrDayInPreview {
    margin: 0px !important;
    padding: 0px !important;
    color: var(--main) !important;
}

.rdrDayEndPreview {
    color: var(--main) !important;
    right: 8px !important;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
}

.rdrSelected,
.rdrInRange,
.rdrStartEdge,
.rdrEndEdge {
    left: 8px !important;
    right: 8px !important;
    top: 3px !important;
    bottom: 3px !important;
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
}

.rdrDayEndOfMonth .rdrInRange,
.rdrDayEndOfMonth .rdrStartEdge,
.rdrDayEndOfWeek .rdrInRange,
.rdrDayEndOfWeek .rdrStartEdge {
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
}

.rdrDayStartOfWeek span {
    /*display: none;*/
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
}

.rdrDayEndOfWeek span {
    /*display: none;*/
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
}


/**
SWITCH
*/

.react-switch-bg {
    box-shadow: inset 0px -1.77827px 5.3348px rgba(0, 0, 0, 0.18203);
    background: var(--main) !important;
}

/**
MULTI SELECT CHECKBOXES
*/

.rmsc .dropdown-container {
    background-color: white !important;
    border: 1px solid var(--main) !important;
    border-radius: 8px !important;
}

.rmsc .select-item:hover {
    padding: 10px;
    margin: 10px;
    border-radius: 8px;
    background: var(--gray) !important;
}

.rmsc .select-item.selected {
    padding: 8px;
    margin: 10px;
    border-radius: 8px;
    background: var(--gray) !important;
}

.dropdown-content {
    z-index: 5 !important;
}

input[type="radio"] {
    cursor: pointer;
    appearance: none;
    box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.214719);
    font: inherit;
    color: var(--main);
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid var(--main);
    border-radius: 50%;
}

input[type="radio"]:checked {
    cursor: default;
    appearance: none;
    background: var(--main);
    box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.214719);
    font: inherit;
    color: var(--main);
    width: 1.15em;
    height: 1.15em;
    border: 0;
    border-radius: 50%;
}

.text-dropdown {
    font-size: 13px;
    border: none;
    margin: 0px;
    padding: 0px;
    color: #5A738E
}

.text-dropdown:hover {
    font-size: 13px;
    border: none;
    margin: 0px;
    padding: 0px;
    color: var(--main)
}

.text-dropdown:focus {
    font-size: 13px;
    border: none;
    margin: 0px;
    padding: 0px;
    color: var(--main)
}

/* LOADER */
.loader {
    border: 5px solid var(--backgrounds);
    border-bottom-color: var(--main);
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}